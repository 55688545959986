@mixin flex {
  display: flex;
}

@mixin flexRow {
  @include flex;
  flex-direction: row;
}

@mixin flexCol {
  @include flex;
  flex-direction: column;
}

@mixin justifyCenter {
  justify-content: center;
}

@mixin justifySpaceBetween {
  justify-content: space-between;
}

@mixin justifySpaceAround {
  justify-content: space-around;
}

@mixin alignStart {
  align-items: flex-start;
}

@mixin alignCenter {
  align-items: center;
}

@mixin linkUnderline {
  font-size: 18px;
  color: $primary-text-black;
  background:
      linear-gradient(
          rgba(0,0,0, 0.1) 0%,
          rgba(0,0,0, 0.15) 100%
      );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 4px 4px;
  color: #000;
  text-decoration: none;
  transition: background-size 1s;

  &:hover {
      background-size: 4px 50px;
  }
}

@mixin vrBefore {
  &::before {
    content: '';
    position: absolute;
    width: 1px;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
    @content;
  }
}

@mixin hrBefore {
  &::before {
    content: '';
    position: absolute;
    height: 1px;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.15);
    @content;
  }
}

@mixin clearBefore {
  &::before {
    content: none;
    position: unset;
    width: unset;
    height: unset;
    top: unset;
    bottom: unset;
    left: unset;
    background-color: unset;
  }
}

@mixin breakpoint($min: 0, $max: 0) {
  $type: type-of($min);
  
  @if $type == string {
    @if $min == xs { 
      @media (max-width: 320px) { @content; }       // Mobile Devices
    }
    
    @else if $min == sm {
      @media (min-width: 640px) { @content; }       // Tablet Devices
    }
    
    @else if $min == md {
      @media (min-width: 992px) { @content; }       // Desktops
    }
    
    @else if $min == lg {
      @media (min-width: 1200px) { @content; }      // Widescreen Desktops
    }
  
    // Otherwise pass a warning to the compiler as to the appropriate options
    @else {
      @warn "The breakpoint mixin supports the following attributes: xs, sm, md, lg";
    }
  }
  @else if $type == number {
    // Allow for custom parameters for min and max size
    $query: "all" !default;
    @if      $min != 0 and $max != 0 { $query: "(min-width: #{$min}) and (max-width: #{$max})"; } // set both min and max
    @else if $min != 0 and $max == 0 { $query: "(min-width: #{$min})"; } // set just min
    @else if $min == 0 and $max != 0 { $query: "(max-width: #{$max})"; } // set just max
    @media #{$query} { @content; }
  }
}